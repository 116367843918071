import revive_payload_client_kyWPb0hUdZ from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@types+node@20.16.11_stylelint@15.11.0_typescript@5.6.3_vite@5.4.10_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_YBpzNB03c3 from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@types+node@20.16.11_stylelint@15.11.0_typescript@5.6.3_vite@5.4.10_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_LXkkmLesGn from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@types+node@20.16.11_stylelint@15.11.0_typescript@5.6.3_vite@5.4.10_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_gtuZzEx1ci from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@types+node@20.16.11_stylelint@15.11.0_typescript@5.6.3_vite@5.4.10_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_sQGJlZ7PUG from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@types+node@20.16.11_stylelint@15.11.0_typescript@5.6.3_vite@5.4.10_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_I9Jy7GSJ9l from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@types+node@20.16.11_stylelint@15.11.0_typescript@5.6.3_vite@5.4.10_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_P5F2OKspO3 from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@types+node@20.16.11_stylelint@15.11.0_typescript@5.6.3_vite@5.4.10_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_SaCLAQ5n0m from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/@pinia+nuxt@0.5.5_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/rialtic/workflows/console-ui/apps/console/.nuxt/components.plugin.mjs";
import prefetch_client_4Pcn0jy9mZ from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@types+node@20.16.11_stylelint@15.11.0_typescript@5.6.3_vite@5.4.10_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/rialtic/workflows/console-ui/apps/console/.nuxt/pwa-icons-plugin.ts";
import pwa_client_5Pq0eqdZhr from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.0.11_rollup@2.79.2_vite@5.4.10_workbox-bui_qnsfv4eo4lsgmepymcbpueqlce/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import unocss_MzCDxu9LMj from "/builds/rialtic/workflows/console-ui/apps/console/.nuxt/unocss.mjs";
import plugin_ym0EGwgS5L from "/builds/rialtic/workflows/console-ui/packages/protected/src/runtime/plugin.ts";
import formkitPlugin_pZqjah0RUG from "/builds/rialtic/workflows/console-ui/apps/console/.nuxt/formkitPlugin.mjs";
import pendo_hkesixFq4o from "/builds/rialtic/workflows/console-ui/packages/nuxt-pendo/src/runtime/pendo.ts";
import datadog_JKmUALlR6H from "/builds/rialtic/workflows/console-ui/packages/nuxt-datadog/src/runtime/datadog.ts";
import plugin_526V8oWKGJ from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/plugin.ts";
import plugin_8fMk05vpWN from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth/src/runtime/plugin.ts";
import plugin_PXQeuxRKoc from "/builds/rialtic/workflows/console-ui/packages/ui/src/runtime/plugin.ts";
export default [
  revive_payload_client_kyWPb0hUdZ,
  unhead_YBpzNB03c3,
  router_LXkkmLesGn,
  payload_client_gtuZzEx1ci,
  navigation_repaint_client_sQGJlZ7PUG,
  check_outdated_build_client_I9Jy7GSJ9l,
  chunk_reload_client_P5F2OKspO3,
  plugin_vue3_SaCLAQ5n0m,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4Pcn0jy9mZ,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_5Pq0eqdZhr,
  unocss_MzCDxu9LMj,
  plugin_ym0EGwgS5L,
  formkitPlugin_pZqjah0RUG,
  pendo_hkesixFq4o,
  datadog_JKmUALlR6H,
  plugin_526V8oWKGJ,
  plugin_8fMk05vpWN,
  plugin_PXQeuxRKoc
]